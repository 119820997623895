import React from 'react'
import './app.scss'
import HeaderCom from './components/Header'
import FooterCom from './components/Footer'
import ContentCom from './components/Content'
export default function App() {
  return (
    <>
      <HeaderCom />
      <ContentCom />
      <FooterCom />
    </>
  )
}

