import React from 'react'

const HeaderCom = () => {
    return (
        <div className='layout-header' >
            <div className="header-logo"></div>
            <div className="header-slogan">
                <p className='slogan-h1'>开启
                    <p>万物互联</p> </p>
                <p className='slogan-h2'>Open the interconnection of all things</p>
                <p className='slogan-h3'>从这里开始</p>
            </div>
        </div>
    )
}
export default HeaderCom