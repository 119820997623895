import React from 'react'

const FooterCom = () => {
    return (
        <div className="footer">
            <div className="content-list">
                <div className="content-list-warp">
                    <div className="list-one">
                        <div className="title">联系我们</div>
                        <div className="content">
                            <div className="li-one"><span className='telephone'></span> 0571-82202561</div>
                            <div className="li-two"><span className='email'></span> info@easyfetch.com</div>
                            <div className="li-three"><span className='position'></span> 杭州市萧山区天人大厦10楼</div>
                        </div>

                    </div>
                    <div className="list-two">
                        <div className="title">产品中心</div>
                        <div className="content">
                            <div className="li-one">物联网智能终端产品</div>
                            <div className="li-two">无线自组网通信产品</div>
                            <div className="li-three">物联网管理平台</div>
                        </div>

                    </div>
                    <div className="list-three">
                        <div className="title">解决方案 </div>
                        <div className="content">
                            <div className="li-one">智慧电力解决方案</div>
                            <div className="li-two">智慧园区解决方案</div>
                            <div className="li-three">智慧农业解决方案</div>
                            <div className="li-four">智慧城市解决方案</div>
                        </div>

                    </div>
                    <div className="list-four">
                        <div className="title">成功案例</div>
                        <div className="content">
                            <div className="li-one">电力应用案例</div>
                            <div className="li-two">园区应用案例</div>
                            <div className="li-three">农业应用案例</div>
                            <div className="li-four">城市应用案例</div>
                            <div className="li-five">其他行业应用案例</div>
                        </div>

                    </div>
                    <div className="list-five">
                        <div className="title">关于我们 </div>
                        <div className="content">
                            <div className="li-one">公司简介</div>
                            <div className="li-two">荣誉与奖项</div>
                            <div className="li-three">发展与创新</div>
                            <div className="li-four">联系我们</div>
                        </div>

                    </div>
                </div>

            </div>
            <div className="content-line"></div>
            <div className="footer-title"> Copyright © 杭州盈飞驰科技有限公司版权所有<a className='flag' target='_blank' href='https://beian.miit.gov.cn'  >浙ICP备2022019961号-2</a>      </div>
        </div>
    )
}
export default FooterCom
