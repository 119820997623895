import React from 'react'
import dianwang from '../../img/dianwang.png'
import shiyou from '../../img/shiyou.png'
import yidong from '../../img/yidong.png'
import alibaba from '../../img/alibaba.png'
import hangming from '../../img/hangming.png'
import shenlan from '../../img/shenlan.png'
import qhua from '../../img/qhua.png'
import zhejiangdax from '../../img/zhejiangdax.png'
import zhguokeji from '../../img/zhguokeji.png'
import mguo from '../../img/mguo.png'



const ContentCom = () => {
    return (
        <div className="layout-content">
            <article className='content-one'>
                <div className="content-title">
                    <p className='content-title-h1'>一站式物联网解决方案 </p>
                    <p className='content-title-h2'>赋能千行百业</p>
                </div>
                <div className="content-show">
                    <div className='content-show-one'>
                        <p className='icon'></p>
                        <p className='h1'>智慧电力解决方案</p>
                        <p className='h2'>绿色能源管理，助力双碳落地</p>
                    </div>
                    <div className='content-show-two'><p className='icon'></p>
                        <p className='h1'>智慧城市解决方案</p>
                        <p className='h2'>构建高效城市运营管理环境</p></div>
                    <div className='content-show-three'><p className='icon'></p>
                        <p className='h1'>智慧农业解决方案</p>
                        <p className='h2'>实现农业精细、高效、绿色发展</p></div>
                    <div className='content-show-four'><p className='icon'></p>
                        <p className='h1'>智慧园区解决方案</p>
                        <p className='h2'>赋能现代化智慧产业园区建设</p></div>
                </div>
            </article>
            <article className='content-two'>
                <div className="content-title">
                    <div className="line-left"></div>
                    <div className="line-right"></div>
                    <p className="h1">我们的优势</p>
                    <p className="h2">  选择我们，让您的物联网方案更加完美</p>
                </div>
                <div className="content-show">
                    <div className="content-icon">
                        <div className="icon-warping">
                            <div className="icon-one">
                                <div className='img'></div>
                                <div className="icon-des">
                                    <div className="title-h1">关键技术</div>
                                    <div className="title-h2">
                                        <p>无线自组织网络技术</p>
                                        <p>低功耗广域网技术</p>
                                        <p>半主动无线传输技术</p>
                                    </div>
                                </div>
                            </div>
                            <div className="icon-two">
                                <div className='img'></div>
                                <div className="icon-des">
                                    <div className="title-h1">核心产品</div>
                                    <div className="title-h2">
                                        <p>低功耗、低成本通信产品  </p>
                                        <p>定制化智能终端产品</p>
                                        <p>面向行业的物联网平台产品</p>
                                    </div>
                                </div></div>
                            <div className="icon-three">  <div className='img'></div>
                                <div className="icon-des">
                                    <div className="title-h1">专利标准</div>
                                    <div className="title-h2">
                                        <p>多项发明专利  </p>
                                        <p>多项实用新型专利和软件著作权</p>
                                    </div>
                                </div></div>
                            <div className="icon-four">  <div className='img'></div>
                                <div className="icon-des">
                                    <div className="title-h1">行业客户</div>
                                    <div className="title-h2">
                                        <p>覆盖12个行业和领域 </p>
                                        <p>服务100多家用户单位</p>
                                    </div>
                                </div></div>
                        </div>
                    </div>
                    <div className="title-h1">
                        马上开启数字化升级之旅
                        <div className="h2">
                            针对不同的业务需求和应用场景提供个性定制化解决方案<p className='br'> 客户至上，用心服务，精益求精，创新高效</p>
                        </div>
                    </div>
                </div>
            </article>
            <article className='content-three'>
                <div className="content-title">
                    <div className="line-left"></div>
                    <div className="line-right"></div>
                    <p className="h1">我们的案例</p>
                    <p className="h2">客户满意度是我们永恒的追求</p>
                </div>
                <div className="content-show">
                    <div className='content-show-warping'>
                        <div className='show-one-img'>
                            <div className="title">
                                智慧电力
                                <p className='h2'>智慧配电房项目</p>
                            </div>
                        </div>
                        <div className='show-two-img'>
                            <div className="title">
                                智慧园区
                                <p className='h2'>智慧海关园区项目</p>
                            </div></div>
                        <div className='show-three-img'>
                            <div className="title">
                                智慧农业
                                <p className='h2'>农用地信息管理项目</p>
                            </div>
                        </div>
                        <div className='show-four-img'>
                            <div className="title">
                                智慧城市
                                <p className='h2'>上海杨浦区示范项目</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-about">
                    <div className="h1">关于盈飞驰 </div>
                    <div className="h2-des">盈飞驰是一家由清华大学创业团队孵化的专业提供物联网解决方案的国家高新技术企业<br /><p className='br'>是国内领先的掌握覆盖多频段、多协议无线自组织网络技术的产业化公司</p> </div>
                    <div className="line-one"></div>
                    <div className="line-two"></div>
                    <div className="number">
                        <div className="number-content">
                            <div className='article'>
                                <p className='num'>12+</p>
                                <p className='des'> 行业和领域</p>
                            </div>
                            <div className='article'>
                                <p className='num'>200+</p>
                                <p className='des'>用户单位</p>
                            </div>
                            <div className='article'>
                                <p className='num'>100w+</p>
                                <p className='des'>平台接入和设备管理</p>
                            </div>
                        </div>

                    </div>

                </div>
            </article >
            <article className='content-four'>
                <div className="content-title">
                    <div className="line-left"></div>
                    <div className="line-right"></div>
                    <p className="h1">合作伙伴</p>
                    <p className="h2">100+企业忠诚信赖，实力完成客户托付</p>
                </div>
                <div className="content-show">
                    <div className="content-show-bg">
                        <div className="flag-icon-one list-item">
                            <img src={dianwang} alt="" />
                        </div>
                        <div className="flag-icon-two list-item">
                            <img src={shiyou} alt="" />
                        </div>
                        <div className="flag-icon-three list-item">
                            <img src={yidong} alt="" />
                        </div>
                        <div className="flag-icon-four list-item">
                            <img src={alibaba} alt="" />
                        </div>
                        <div className="flag-icon-five list-item">
                            <img src={hangming} alt="" />
                        </div>
                        <div className="flag-icon-six list-item">
                            <img src={shenlan} alt="" />
                        </div>
                        <div className="flag-icon-seven list-item">
                            <img src={qhua} alt="" />
                        </div>
                        <div className="flag-icon-eight list-item">
                            <img src={zhejiangdax} alt="" />
                        </div>
                        <div className="flag-icon-nine list-item">
                            <img src={zhguokeji} alt="" />
                        </div>
                        <div className="flag-icon-ten list-item">
                            <img src={mguo} alt="" />
                        </div>

                    </div>
                </div>
            </article>
            <article className='content-end'>
                <div className="left-flag"></div>
                <div className="right-title">申请体验，开启工业互联网新时代</div>
            </article>
        </div >
    )
}
export default ContentCom
